import React from "react";
import PropTypes from 'prop-types';
import "../../assets/styles/Home/index.scss";
import { Footer } from "../../components/footer";
import Header  from "../../components/header";
import { LandingPageContent } from "./content";


const HomePage = ({ userData, dataPoll, dataMagiklinkID, magickLinkCta }) => {
  return (
    <React.Fragment>
      <Header userData={userData} magickLinkCta={magickLinkCta} />
      <LandingPageContent
        dataPoll={dataPoll}
        dataMagiklinkID={dataMagiklinkID}
      />
      <Footer />
    </React.Fragment>
  );
};

HomePage.propTypes = {
  userData: PropTypes.object.isRequired,
  dataPoll: PropTypes.number.isRequired,
  dataMagiklinkID: PropTypes.number.isRequired,
  magickLinkCta: PropTypes.bool
}

export default HomePage;
